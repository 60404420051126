import ImgPizzaBandejeo from '../../public/images/optimized/IMG-20210221-WA0000.jpg';
import ImgCocinero from '../../public/images/optimized/PXL_20230430_001538726.PORTRAIT.jpg';
import ImgEntradaSalmonJamon from '../../public/images/optimized/tortaspic.webp';
export const DataEvents = [
  {
    id: 1,
    image: ImgCocinero,
  },
  {
    id: 7,
    image: ImgEntradaSalmonJamon,
  },
  {
    id: 17,
    image: ImgPizzaBandejeo,
  },
];
